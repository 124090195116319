import { webClient } from "@api/web/WebClient";
import { createErrorToast } from "@components/ToastContainer";
import { useQueryParameter } from "@hooks/navigation";
import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";


export default () => {
	const [token] = useQueryParameter("verification_token");

	useEffect(
		() => {

			const apiCall = async () => {
				try {
					await webClient().webAccountsVerifyEmailUsingJwtPost(token, {});

					window.location.href = ("/account/verify-email-success");
				}
				catch (e: unknown) {
					const error = e as Error;
					createErrorToast(error.message)
					console.error(error);
				}
			}

			apiCall();
		},
		[token]);

	return <CircularProgress />
}